import { useQueryClient } from '@tanstack/vue-query'
import { fetchApiReportTemplates } from '@reports/composables'

export default defineNuxtRouteMiddleware(async ({ path, params, query }) => {
  const divisionId = params.companyId as string
  const templateId = query.template as string | undefined

  try {
    const queryClient = useQueryClient()
    const templates = await queryClient.ensureQueryData({
      queryKey: ['getTemplates', divisionId],
      queryFn: () => fetchApiReportTemplates(divisionId),
    })

    if (!templateId || !templates.some((t) => t.id === templateId))
      throw new Error('Template not found')
  } catch (e) {
    return navigateTo(`/division/${divisionId}/reports`)
  }
})
